/* You can add global styles to this file, and also import other style files */

// ----------------------------------------- fonts -----------------------------------------
@font-face {
    font-family: 'Ford Antenna Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Italic'), url('./fonts/ford-antenna-italic-588ce13ac85a5.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Regular XCnd';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Regular XCnd'), url('./fonts/ford-antenna-regular-xcnd-589558121012a.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Thin Italic XCnd';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Thin Italic XCnd'), url('./fonts/ford-antenna-thin-italic-xcnd-58894adb8be4f.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Medium Cnd';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Medium Cnd'), url('./fonts/ford-antenna-medium-cnd-587bd97171cbd.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Medium'), url('./fonts/ford-antenna-medium-58955836e60d2.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Medium Italic Cmp';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Medium Italic Cmp'), url('./fonts/ford-antenna-medium-italic-cmp-588e52bbb4e9f.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Semibold Italic'), url('./fonts/ford-antenna-semibold-italic-588ce16abbe89.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Bold Italic Cmp';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Bold Italic Cmp'), url('./fonts/ford-antenna-bold-italic-cmp-587bd989c0b2d.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Bold Italic Cnd';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Bold Italic Cnd'), url('./fonts/ford-antenna-bold-italic-cnd-58894aad940e0.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Ford Antenna Black Cmp';
    font-style: normal;
    font-weight: normal;
    src: local('Ford Antenna Black Cmp'), url('./fonts/ford-antenna-black-cmp-587eb6f0d9e46.woff') format('woff');
    }

    @font-face {
        font-family: 'Ford Antenna Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Ford Antenna Regular'), url('./fonts/ford-antena/FordAntenna-Regular.ttf') format('ttf');
    }

    @font-face {
        font-family: 'Ford Antenna Medium';
        font-style: normal;
        font-weight: normal;
        src: local('Ford Antenna Medium'), url('./fonts/ford-antena/FordAntenna-Medium.ttf') format('ttf');
    } 


    @font-face {
        font-family: 'Ford F1 Bold';
        font-style: normal;
        font-weight: normal;
        src: local('Ford F1 Bold'), url('fonts/F1_font/FordF-1-Bold.otf') format('opentype');
    }

    @font-face {
        font-family: 'Ford F1 Light';
        font-style: normal;
        font-weight: normal;
        src: local('Ford F1 Light'), url('fonts/F1_font/FordF-1-Light.otf') format('opentype');
    }

    @font-face {
        font-family: 'Ford F1 Medium';
        font-style: normal;
        font-weight: normal;
        src: local('Ford F1 Medium'), url('fonts/F1_font/FordF-1-Medium.otf') format('opentype');
    }


    // @font-face {
    //     font-family: 'Ford F1 Regular';
    //     font-style: normal;
    //     font-weight: normal;
    //     src: local('Ford F1 Regular'), url('FordF-1-Regular.woff') format('woff');
    // }
    // @font-face {
    //     font-family: 'Ford F1 Regular';
    //     font-style: normal;
    //     font-weight: normal;
    //     src: local('Ford F1 Regular'), url('./FordF-1-Regular.woff') format('woff');
    // }

    @font-face {
        font-family: 'Ford F1 Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Ford F1 Regular'), url('/assets/fonts/F1_font/FordF-1-Regular.woff') format('woff');
    }
    // @font-face {
    //     font-family: 'Ford F1 Regular';
    //     font-style: normal;
    //     font-weight: normal;
    //     src: local('Ford F1 Regular'), url('./assets/fonts/F1_font/FordF-1-Regular.woff') format('woff');
    // }

    // @font-face {
    //     font-family: 'Ford F1 Regular';
    //     font-style: normal;
    //     font-weight: normal;
    //     src: local('Ford F1 Regular'), url('fonts/F1_font/FordF-1-Regular.woff') format('woff');
    // }
    // @font-face {
    //     font-family: 'Ford F1 Regular';
    //     font-style: normal;
    //     font-weight: normal;
    //     src: local('Ford F1 Regular'), url('./fonts/F1_font/FordF-1-Regular.woff') format('woff');
    // }


    // ----------------------------------------- end of fonts -----------------------------------------

    // ----------------------------------------- fonts overrides -----------------------------------------
    .mat-h1,
    .mat-headline-5,
    .mat-typography .mat-h1,
    .mat-typography .mat-headline-5,
    .mat-typography h1,
    .mat-mdc-card-title,
    .mdc-button,
    .mat-mdc-option,
    .mat-mdc-select-value,
    .mat-typography h2,
    .mat-typography h3,
    .mat-mdc-dialog-container .mdc-dialog__content,
    .mdc-button__label,
    .mat-h4,
    .mat-body-1,
    .mat-typography .mat-h4,
    .mat-typography .mat-body-1,
    .mat-typography h4  {
        font-family: 'Ford F1 Regular', 'Ford Antenna Regular', "Helvetica Neue", sans-serif!important;
    }

    a{
        cursor: pointer !important;
    }
    
    .semibold{
        font-weight: 500;
    }

    .bold{
        font-weight: 600;
    }

    .big-content{
        font-size: 18px;
    }

    .big-content-link{
        font-size: 18px;
        font-weight: 600;       
    }

    .blue-modal-text{
        text-decoration: underline;
        color: #066FEF;
    }

    .main-text{
        text-transform: uppercase;
    }

    .align-bottom{
        vertical-align: bottom;
    }

    // ----------------------------------------- end of fonts overrides -----------------------------------------
    
    // ----------------------------------------- global rules -----------------------------------------

   
    html, body { height: 100%; }
    
    html{
        scroll-behavior: smooth;
    }

    body { 
        margin: 0; 
        font-family: 'Ford F1 Regular', 'Ford Antenna Regular', "Helvetica Neue", sans-serif!important;
        background-color: #F0F0F0;  
        margin-top: 60px;     
    }

    mat-card:hover{
        border: 1px solid #0073e6 !important;
    }
  
    mat-card:hover .mat-mdc-raised-button:not(:disabled) {
        background-color: #ecf2fa !important;
    }

    .mat-mdc-raised-button, .mat-mdc-unelevated-button{
        --mat-mdc-button-persistent-ripple-color: transparent !important;
    }

    mat-card:hover mat-card-title {
        color: #0073e6;
        font-weight: bold;
    }
    
    mat-card:hover mat-card-content {
        color: #0073e6;
    }

    .fullHeight{
        min-height: calc(100% - 280px);
        padding-top: 30px;
    }

    .flex-start{
        align-items: flex-start!important;
    }

    .flex-start section{
        margin: 0 0!important;
    }
    
    .modalForDetailedSection .btm-therms{
        padding: 0 !important;
      }

    .btm-therms{
        font-size: 10px;
        margin-top: 50px;
        margin-bottom: 20px;
        padding-bottom: 10px;
    }

    .btm-therms section{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .btm-therms section a{
        word-break: break-all;
        margin: 0 0 11px 5px;
    }

    .modal-therms{
        margin-left: 50px;
        margin-top: 0;
    }

    .btm-therms p {
        line-height: 1.3;
        color: #6E6E6E;
    }

    .btm-therms p a {        
        color: #6E6E6E;
        word-wrap: break-word;
    }

    .vertical-middle{
        vertical-align: middle;
    }

    .hidden{
        display: none!important;
    }
    
    .makePage100vh{
        min-height: 100vh;
        height: fit-content;
    }
    
    .width-100{
        width: 100%;
    }
    
    .contentWrapper{
        position: relative;
        background: inherit;
        max-width: 1150px;
        margin: auto;
        padding: 0 15px;
        min-height: calc(100% - 371px);
    }
    
    .page-header{
        font-size: 18px;
        font-weight: 600;
        color: #00095B;
    }
    
    .page-header a{
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        color: #000000;
    }
    
    .gray-subtitle{
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        color: #066FEF;
    }
    
    .text-center {
        text-align: center;
    }

    .text-left{
        text-align: left!important;
    }
   
    .inline-element{
        display: inline-block;
    }

    .right-float{
        float:right;
    }
    
    .margin-15{
        margin: 15px;
    }
    
    .mt-10{
        margin-top: 10px;
    }
    
    .mt-20{
        margin-top: 20px;
    }
    
    .mt-30{
        margin-top: 30px;
    }

    .mb-0{
        margin-bottom: 0px!important;
    }

    .mb-5{
        margin-bottom: 5px!important;
    }
    
    .mb-10{
        margin-bottom: 10px;
    }
    
    .mb-20{
        margin-bottom: 20px;
    }
    
    .mb-30{
        margin-bottom: 30px!important;
    }
    
    .mr-10{
        margin-right: 10px;
    }
    
    .mr-20{
        margin-right: 20px;
    }
    
    .mr-30{
        margin-right: 30px;
    }
    
    .ml-10{
        margin-left: 10px;
    }
    
    .ml-20{
        margin-left: 20px!important;
    }
    
    .ml-30{
        margin-left: 30px;
    }
    
    .p-20{
        padding: 20px;
    }
    
    .pb-30{
        padding-bottom: 30px;
    }    
    
    .mobile-block{
        display: none;
    }
    
    .desktop-block{
        display: block;
    }
    
    
    .flex-layout{
        display: flex;
    }
    
    
    .min100vh{
        min-height: 100vh;
    }
    
    .breadcrumbs,
    .breadcrumbs a{
        font-size: 14px;
        text-decoration: none;
        color: #000000;
        margin-bottom: 10px;
    }
    
    .mat-typography h1,
    .content-title{
        color: #00095B;
        font-size: 18px;
        font-weight: bold;
    }
    
    .content-text{
        font-size: 14px;
    }
    
    .main-text{
        color: #00142E;
        font-size: 14px;
    }

    .doNotApplyUppercase{
        text-transform: none !important;
    }
    
    .tooltip_image{
        margin-left:20px;
        vertical-align: middle;
    }

    .content-title .tooltip_image{
        margin-left:10px;
        vertical-align: text-top;
    }
    
    .main-button{
        background-color: #FFFFFF !important;
        border: 1px solid #066FEF !important;
        border-radius: 30px !important;
        display: inline-block !important;
        width: 200px !important;
        height: 42px !important;
        font-size: 14px !important;
        color: #066FEF !important;
        text-decoration: none !important;
        vertical-align: middle !important;
        line-height: 3 !important;
    }
    
    .main-link{
        color: #066FEF;
        font-size: 14px;
    }
    
    .mat-mdc-card-title{
        font-size: 16px!important;
        font-weight: bold!important;
        color: #00095B;
    }
    
    .mat-mdc-card-content{
        font-size: 14px!important;
        color: #4D4D4D;
    }

    .no-results{
        color: #00095B;
        font-size: 14px;
        font-weight: bold;
    }

    .niceLineFromNothingHeader{
        color: #00142E !important;
        transition: .3s;
    }

    .niceLineFromNothingHeader:hover{
        color: #066FEF !important;
    }

    .niceLineFromNothingHeader img{
        transition: .3s !important;
    }

    .niceLineFromNothingHeader:hover img{
        filter: brightness(15) !important;
    }
    
    .niceLineFromNothingHeader {
        text-decoration: none !important;
        display: inline-block !important;
        position: relative !important;
        margin: 0;
      }
      .niceLineFromNothingHeader:after {    
        background: none repeat scroll 0 0 transparent;
        bottom: -15px;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #066FEF;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      .niceLineFromNothingHeader:hover:after { 
        width: 100%; 
        left: 0%; 
      } 

      .noMargin{
        margin: 0 !important;
      }

      .link-block{
        padding-top: 5px;
      }

    .niceLineFromNothing {
        text-decoration: none !important;
        display: inline-block !important;
        position: relative !important;
      }
      .niceLineFromNothing:after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #00142E;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      .niceLineFromNothing:hover:after { 
        width: 100%; 
        left: 0; 
      } 

      .header-link svg,
      .header-link img,
      .fge-logo
      {
        filter: drop-shadow(0px 0px 0px transparent);
        transition: .3s;
      }
    
      .header-link svg:hover,
      .header-link img:hover,
      .fge-logo:hover{
        filter: drop-shadow(2px 2px 5px #3C4382);
      }
    
    @media only screen and (max-width: 768px) {
      .mobile-block{
        display: block;
      }  
    
      .desktop-block{
        display: none;
      }
      
      .header-link-text{
        display: none;
      }
      
      .main-button{
        width: 130px !important;
        height: 32px !important;
        line-height: 2.4 !important;
      }         
     
    }

    .niceLineFromSomething {
        text-decoration: none;
        display: inline-block;
        position: relative;
        z-index: 2;
      }

    .colorItLikeDarkLink{
        color: #066FEF !important;
    }

      .differentColor::after, .colorItLikeDarkLink::after{
        background: #066FEF !important;
      }
      .niceLineFromSomething:after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 3px;
        content: "";
        display: block;
        height: 1px;
        left: 0%;
        position: absolute;
        background: #066FEF;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 100%;
        z-index: 1;
      }
      .niceLineFromSomething:hover:after { 
        width: 0; 
        left: 50%; 
      }
    // ----------------------------------------- end of global rules -----------------------------------------
    
    // ----------------------------------------- fge-interactive-map component -----------------------------------------
    app-about-fge mat-drawer-container, app-animated-map-part2 mat-drawer-container{
        background: #F0F0F0 !important;
    }

    mat-drawer-container{
        z-index: 10000!important;
    }
    
    .navigationForOnboardingComponent{
        display: block;
        // padding: 10px 0;
        padding: 0 0 30px 0;
        // position: fixed;
        top: 65px;
        z-index: 100;
        background: #F0F0F0;
        border-radius: 15px;
    }
    
    .navigationForOnboardingComponent a, .navigationForOnboardingComponent span{
        text-decoration: none;
        color: #00142E;
        font-size: 14px;
    }

    .navigationForOnboardingComponent a,
    .breadcrumbs a {
        text-decoration: none !important;
        display: inline-block !important;
        position: relative !important;
      }
      .navigationForOnboardingComponent a:after,
      .breadcrumbs a::after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        background: #00142E;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      .navigationForOnboardingComponent a:hover:after,
      .breadcrumbs a:hover::after { 
        width: 100%; 
        left: 0; 
      } 

      .colorItLikeLink{
        color: #066FEF;
      }
    
    .outerComponentColor{
        background: #F0F0F0;
    }
    
    .wrapMapComponent{
        position: relative;
        background: inherit;
        max-width: 1150px;
        margin: auto;
        padding: 30px 15px 0 15px;
        margin-bottom: 48px;
        font-size: 16px;
        color: #00142E;
    }
    
    .footerForMapComponent{
        background: #fff;
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 9999;
    }
    
    .wrapfootercomponents{
        max-width: 1150px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .wrapfootercomponents section{
        width: 33.333%;
        display: flex;
        align-items: center;
    }
    
    .wrapfootercomponents section:first-child{
        justify-content: flex-start;
        margin-right: 15px;
    }
    
    .wrapfootercomponents section:last-child{
        justify-content: flex-end;
        margin-left: 15px;
    }
    
    .mb-20-custom{
        margin-bottom: 20px !important;
    }
    // .wrapfootercomponents section:last-child{
    //     padding-right: 0px;
    // }
    
    .wrapfootercomponents section p{
        margin: 0;
    }
    
    .goBackLink{
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: bold;
        color: #00095B;
        font-size: 14px;
        margin: 5px 0 60px 0;
    }

    .goBackLink {
        text-decoration: none;
        display: inline-block;
        position: relative;
          z-index: 2;
      }
      .goBackLink:after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 100%;
        position: absolute;
        background: #00095B;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0%;
          z-index: 1;
      }
      .goBackLink:hover:after { 
        width: 100%; 
        left: 0%; 
      }
      
      .goBackLink svg{
        position: relative;
        top: 2px;
      }

    
    .sectionForTheDetailedPage h2, .longDetailPageWrapper h2{
        font-size: 40px;
        color: #066FEF;
        font-weight: bold;
        line-height: 45px;    
    }
    
    .sectionForTheDetailedPage h3, .longDetailPageWrapper h3{
        font-size: 14px;
        color: #00142E;
        text-transform: uppercase;
    }
    
    .headingParagraph{
        color: #00095B;
        font-size: 22px;
        font-weight: bold;
        line-height: 28px;
    }
    
    .headingParagraph, .slightlyBiggerParagraph, .slightlySmallerParagraph, .wrapCompleteButtonAndText, .wrapCompleteButtonAndText p{
        margin-top: 25px !important;
    }
    
    .slightlyBiggerParagraph, .slightlySmallerParagraph{
        color: #00142E;
    }
    
    .slightlyBiggerParagraph{
        font-size: 16px;
    }
    
    .slightlyBiggerParagraph ul {
        padding-left: 15px;
    }
    
    .slightlySmallerParagraph{
        font-size: 14px;
    }
    
    .wrapCompleteButtonAndText{
        position: relative;
        bottom: 0px;        
    }
    
    .wrapCompleteButtonAndText p{
        color: #4D4D4D;
        font-size: 14px;
        margin-bottom: 0;
    }

    .wrapCompleteButtonAndText a{
        text-decoration: none !important;
    }
    
    .wrapCompleteButtonAndText a button{
        color: #066FEF !important;
        font-size: 16px !important;
        border: 1px solid #066FEF;
        border-radius: 25px;
        padding: 0 55px;
    }
    
    .sectionForTheDetailedPage, .longDetailPageWrapper{
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    
    #ignoreSectionForTheDetailedPageRules{
        display: block !important;
    }
    
    .biggerHeadingParagraph{
        font-size: 22px;
        font-weight: bold;
        color: #00095B;
    }
    
    .wrapImageInsideSectionForTheDetailedPage, .wrapTextInsideSectionForTheDetailedPage .wrapCompleteButtonAndText{
        margin-bottom: 50px;
    }
    
    .wrapTextInsideSectionForTheDetailedPage{
        width: 100%;
    }
    
    .wrapImageInsideSectionForTheDetailedPage{
        width: 100%;
        position: relative;
        min-height: 550px!important;
    }
    
    .paused {
        animation-play-state: paused !important;
    }
    
    @keyframes fadeInLoop {
        0%{
            scale: 0;
        }
        25% {
            scale: 1
        }
        100% {
            scale: 0;
        }
    }
    
    .button-container-round-2 {
        animation: fadeInLoop 1s ease-in-out infinite;
        transform-origin: center center;
    }
    
    .button-container-round {
        animation: fadeInLoop 2s ease-in-out infinite;
        transform-origin: center center;
    }
    
    @media only screen and (max-width: 1165px) {
        .wrapfootercomponents{
            padding: 0 0 0 15px;
        }
    }
    
    @media only screen and (max-width: 1050px) {
        .wrapImageInsideSectionForTheDetailedPage img{
            width: 525px;
            display: block;
            margin: auto;
        }
    
        .wrapCompleteButtonAndText {
            position: relative;
            bottom: auto;
        }
    
        .navigationForOnboardingComponent{
            display: block;
            position: relative;
            top: 0;
            z-index: 100;
            background: #F0F0F0;
            border-radius: 15px;
        }
    
        .sectionBeforeAnimatedContent {
            margin: 0 0 25px 0 !important;
        }
    }
    
    @media only screen and (max-width: 991px) {
        .wrapfootercomponents section p{
            font-size: 12px;
        }

        .wrapfootercomponents section:first-child{
            margin-right: 5px;
        }
        
        .wrapfootercomponents section:last-child{
            margin-left: 5px;
        }
    }
    
    @media only screen and (max-width: 768px) {
        .btm-therms section{
            display: block;
            margin: 0;
        }

        .sectionForTheDetailedPage, .longDetailPageWrapper{
            flex-direction: column;
        }
    
        .wrapTextInsideSectionForTheDetailedPage .wrapCompleteButtonAndText {
            margin-bottom: 25px;
        }
    
        .wrapfootercomponents section p{
            font-size: 8px;
        }

        .wrapImageInsideSectionForTheDetailedPage{
            min-height: auto!important;
        }
    }
    
        
    
    @media only screen and (max-width: 568px) {
        .wrapImageInsideSectionForTheDetailedPage img{
            width: 290px;
        }  
        
        .wrapTextInsideSectionForTheDetailedPage .wrapCompleteButtonAndText {
            margin-bottom: 15px;
        }
    }
    // ----------------------------------------- end of fge-interactive-map component -----------------------------------------
    
    // ----------------------------------------- slide menu component -----------------------------------------
    mat-drawer{
        width: 515px;
        position: fixed !important;
        overflow: auto;
        height: 100%;
    }
    
    .mat-drawer-inner-container::-webkit-scrollbar {
        width: 0.5em;
    }
     
    .mat-drawer-inner-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     
    .mat-drawer-inner-container::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    //   outline: 1px solid slategrey;
    }
    
    //firefox
    
      .mat-drawer-inner-container {
        scrollbar-width: 15px;
      }
    
    
    .wrapSlideMenuHeader, .headerDivInSlideMenuBody{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px auto;
        padding: 0 15px;
        max-width: 66%;
    }
    
    .wrapSlideMenuHeader{
        margin-bottom: 25px;
        margin-top: 75px;
    }
    
    .mainNavSectionInSlideMenuBody{
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 20px auto;
        padding: 0 15px;
        max-width: 66%;
    }
    
    .wrapSlideMenuHeader p{
        margin: 0 10px 0 0;
        color: #00095B;
        font-weight: 300;
        font-size: 13px;
    }
    
    .wrapSlideMenuHeader a {
        margin: 0 0 0 10px;
        border-radius: 25px;
        border: 1px solid #066FEF;
        padding: 20px 30px;
        color: #066FEF !important;
        font-weight: 300;
        background: #fff;
        font-size: 13px;
    }
    
    .headerDivInSlideMenuBody h4 {
        margin: 0 15px 0 0;
        color: #00095B;
        font-size: 16px;
    }
    
    .headerDivInSlideMenuBody p{
        margin: 0 0 0 31px;
        color: #6E6E6E;
        font-size: 14px;
    }
    
    .navigationDotsInSlideMenu {
        display: flex; 
        justify-content:center; 
        flex-direction: column;
        align-items: center;
        box-sizing: border-box; 
        width: auto; 
        height: auto; 
        margin-right: 15px;
    }
    
    .navigationDotsInSlideMenu a.dots  {
        width: 16px; 
        height: 16px; 
        border: 2px solid #B4B4B4; 
        border-radius: 50%; 
        transition: .3s; 
        cursor: default;
    }
    
    .navigationDotsInSlideMenu .mini-dot {
        width: 16px; 
        height: 16px; 
        transform: scale(0) ; 
        background-color: #066FEF; 
        border: none; 
        border-radius: 50%; 
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .navigationDotsInSlideMenu .mini-dot.activeDot {
        transform: scale(1);
    }
      
    .navigationDotsInSlideMenu a.dots.outerBorder{
        border: 2px solid #066FEF; 
      }
    
    .navigationDotsInSlideMenu span {
        height: 14px;
        border: 1px dashed #B4B4B4;
        width: 0px;
    }
    
    .wrapDotsAndWords{
        display: flex;
        justify-content: left;
        align-items: center;
    }
    
    .textCloseToDotsInSideMenu p{
        display: inline;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 320px;
    }
    
    .textCloseToDotsInSideMenu a{
        display: inline-block;
        margin: 0 0 14px 0;
        width: 100%;
        cursor: pointer;
        font-size: 14px;
        color: #6E6E6E;
    }
    
    .textCloseToDotsInSideMenu a:last-child{
        margin: 0;
    }
    
    .wrapLinkInSideMenu{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .checkMarkInSideMenu{
        border: none !important;
        color: #fff;
        display: block;
        width: 10px !important;
        height: 18px !important;
        background: transparent !important;
    }
    
    .wrapTextAndSVGInSideMenu{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    
    .wrapTextAndSVGInSideMenu p{
        margin: 0 15px 0 0;
        color: #066FEF;
        font-size: 14px;
        font-weight: 400;
    }
    
    .closeButtonInSideMenu{
        position: fixed !important;
        bottom: 0 !important;
        max-width: 515px !important;
        width: 100% !important;
        padding: 0 !important;
        height: 60px !important;
        border-radius: 0 !important;
    }
    
    .wrapSlideMenuBody{
        padding-bottom: 25px !important;
    }

    .wrapSlideMenuBody:nth-child(3){
        padding-bottom: 50px !important;
    }
    
    @media only screen and (max-width: 991px) {
    
        .containerInMap a.dots, .containerInMap .mini-dot {
            width: 8px !important;
            height: 8px !important;
        }
    
        .containerInMap span {
            width: 5px !important;
        }
    
        .viewSections{
            display: none;
        }
    }
    
    @media only screen and (max-width: 768px) {
        mat-drawer{
            width: 100%;
            max-width: 100%;
        }
    
        .closeButtonInSideMenu{
            max-width: 100% !important;
        }
    
        .wrapSlideMenuHeader, .headerDivInSlideMenuBody{
            max-width: 100%;
        }
    
        .wrapSlideMenuHeader{
            flex-direction: column;
            align-items: baseline;
        }
    
        .wrapSlideMenuHeader p {
            margin-bottom: 20px;
        }
        
        .mainNavSectionInSlideMenuBody{
            max-width: 100%;
        }
    
        .textCloseToDotsInSideMenu p{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 320px;
        }
    }
    
    @media only screen and (max-width: 400px) {
        .textCloseToDotsInSideMenu p{
            width: 215px;
        }

        .navigationDotsInSlideMenu span{
            height: 12px;
        }
    
        .textCloseToDotsInSideMenu a{
            margin: 0 0 12px 0;
            font-size: 12px;
        }
    
        .wrapSlideMenuHeader a, .wrapSlideMenuHeader p {
            font-size: 10px;
        }
    }
    
    // ----------------------------------------- end of slide menu component -----------------------------------------
    
    
    // ----------------------------------------- support guide -> details page -----------------------------------------
    .linkInTheCategoryCard{
        text-decoration: none;
        height: max-content !important;
    }
    
    .linkInTheCategoryCard:visited{
        color: inherit;
    }
    // ----------------------------------------- end of support guide -> details page -----------------------------------------
    
    // ----------------------------------------- home page -----------------------------------------
    .linkInTheCategoryCard{
        display: flex !important;
        align-items: baseline;
    }
    
    .wrapContent{
        text-align: left !important;
    }
    // ----------------------------------------- end of home page -----------------------------------------
    
    // ----------------------------------------- home page -> search -----------------------------------------
    .highlighted {
        background-color: yellow !important;
        font-weight: bold !important;
    }
    
    // ----------------------------------------- end of home page -> search -----------------------------------------
    
    // ----------------------------------------- interactive map -> detail-page -> modal -----------------------------------------
    #regularLightBlue, #regularLightBlue2{
        color: #066FEF;
        text-transform: uppercase;
        font-size: 14px ;
    }

    .sectionOnDetailedSection {
        font-size: 14px;
        color: #00142E;
        text-transform: uppercase;
    }
    
    #regularLightBlue2{
        margin-bottom: 16px;
        font-weight: 400;
    }
    
    #secondSection2 .verticalLine{
        margin: 0 20px;
        height: 700px !important;
    }
    
    #secondSection2 .wrapTextInModal section:first-child{
        width: 55%;
    }
    
    #secondSection2 .wrapTextInModal section:last-child{
        width: 60%;
    }
    
    #secondSection2 .wrapTextInModal{
        display: flex;
        align-items: flex-start;
        padding: 0;
    }
    
    .customFlexInModal{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }

    .customFlexInModalWithImage{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding-left: 25px;
    }

    .addSomeFlexColomn{
        flex-direction: column;
    }
    
    .customFlexInModal img, 
    .customFlexInModalWithImage img{
        width: 100%;
        max-width: 230px;
        max-height: fit-content;
    }
    
    .noPaddingInModal{
        padding: 0 !important;
    }
    
    #secondSection2 .mat-mdc-dialog-content::-webkit-scrollbar {
        width: 0.2em;
    }
     
    #secondSection2 .mat-mdc-dialog-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     
    #secondSection2 .mat-mdc-dialog-content::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    //   outline: 1px solid slategrey;
    }
    
    //firefox
    
      #secondSection2 .mat-mdc-dialog-content {
        scrollbar-width: none;
      }
    
    .flip-card {
        background-color: transparent;
        width: 300px;
        height: 200px;
        perspective: 1000px;
        cursor: pointer;
      }
      
      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-radius: 10px;
        border: 2px solid #066FEF;
      }
      
      .flip-card.customClicked .flip-card-inner {
        transform: rotateX(180deg);
        border-radius: 0px;
        border: 0px solid #fff;
        box-shadow: none;
        display: block;
        transition: transform 0.6s;
      }
      
      .crossedText {
        color: #B3B3B3 !important;
        text-decoration: line-through;
        opacity: 1;
        scale: 1;
        transition: 0.3s;
      }
    
      .hiddenAgain{
        opacity: 0;
        scale: 0;
        transition: 0.3s;
      }
    
      .flip-card-back{
        font-weight: 900;
        color: #066FEF;
      }
    
      .visibleAgain{
        opacity: 1;
        scale: 1;
        transition: 0.3s;
      }
      
      .flip-card-front {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .flip-card-front,
      .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
      
      .flip-card-back {
        transform: rotateX(180deg);
      }
      
    
    .specialArrowInTheModal{
        font-size: 40px;
        color: #066FEF;
    }
    
    .blueColorText{
        color: #066FEF;
    }
    
    .wrapAllSide{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    #secondSection1 .wrapTextInModal{
        padding: 0 250px 0 30px;
    }
    
    .hidden{
        display: none;
    }
    
    .customPaddingInModal{
        padding-left: 18px !important;
    }
    
    .modalForDetailedSection{
        width: 900px;
    }

    #share{
        width: 540px !important;
        height: 220px !important;
    }

    #share .buttonInModal{
        color: #066FEF !important;
        font-size: 14px !important;
        border: 1px solid #066FEF;
        border-radius: 25px;
        padding: 10px 20px;
        margin-left: 18px;
        font-weight: 400;
        background-color: #fff !important;
        cursor: pointer;
        width: fit-content;        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #feedbackField2{
        resize: none;
        padding: 12px;
        margin: auto;
        border: none;
        background: #ECEBFA;
        border-radius: 10px;
        margin-top: 30px;
        color: #00142E;
        font-size: 14px;
        width: 300px;
    }
    
    .closeIconInModals{
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        z-index: 2;
    }
    
    .wrapImagesInModal{
        padding-left: 25px;
    }
    
    .modalForDetailedSection h2{
        margin-top: 25px;
        margin-left: 25px;
        font-weight: bold !important;
        color: #066FEF !important;
        font-size: 22px !important;
        display: flex;
    }
    
    .wrapBottomNavForCarousel{
        display: flex !important;
        justify-content: space-between !important;
        align-items: center !important;
    }
    
    .wrapBottomNavForCarousel button{
        margin: 0 10px 20px 10px !important;
    }
    
    .wrapNavigarionForCarousel{
        display: flex;
        margin: 0 0 20px 35px;
    }
    
    .wrapNavigarionForCarousel span{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 0 10px;
    }
    
    .modalForDetailedSection h2 svg{
        padding-left: 0 !important;
    }
    
    .wrapCustomWordsInModal{
        max-width: 600px;
        margin: auto;
    }
    
    #secondSection4 .wrapTextInModal{
        padding: 0 30px 0 30px;
    }
    
    .wrapCustomWordsInModal p{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 35px 0 !important;
    }
    
    .wrapCustomWordsInModal span{
        width: 33.333%;
    }
    
    #authenticity{
        font-size: 19px;
        color: #066FEF;
    }
    #kept_promises{
        font-size: 19px;
        color: #066FEF;
    }
    #trust{
        font-size: 63px;
        color: #00095B;
    }
    #confidence{
        font-size: 30px;
        color: #1F00FF;
    }
    #support{
        font-size: 30px;
        color: #1F00FF;
    }
    #communication{
        font-size: 30px;
        color: #00095B; 
    }
    
    // #firstSlide,
    // #secondSlide,
    // #thirdSlide,
    // #fourthSlide,
    // .wrapBottomNavForCarousel button {
    //     display: none;
    //     opacity: 0;
    //     transform: scale(0);
    //     transition: opacity 0.3s, transform 0.3s;
    // }
    
    #secondSection2 #firstSlide,
    #secondSection2 #secondSlide,
    #secondSection2 #thirdSlide,
    #secondSection2 #fourthSlide{
        padding-left: 30px !important;
    }
    
    .disabledSlide{
        opacity: 0 !important;
        transform: scale(0) !important;
        transition: opacity 0.3s, transform 0.3s !important;
    }
    
    .activeSlide,
    .activeButton{
        opacity: 1 !important;
        transform: scale(1) !important;
        transition: opacity 0.3s, transform 0.3s !important;
    }
    
    .activeSlide{
        display: block !important;
    }
    
    .activeButton{
        display: flex !important;
    }
    
    .inActiveDotForCarousel{
        background: #B4B4B4;
    }
    
    .activeDotForCarousel{
        background: #066FEF;
    }
    
    .proTipSpan{
        padding-left: 15px;
        font-size: 18px;
    }

    .proTipSpan.no-left-padding{
        padding-left: 0;
    }
    
    .navigationForOnboardingComponent h1{
        font-weight: bold;
        font-size: 40px;
        color: #00095B;
        margin: 50px 0 25px 0;
        line-height: 50px;
    }
    
    .sectionBeforeAnimatedContent{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        background: #fff;
        padding: 20px;
        border-radius: 10px;
        // margin: 125px 0 50px 0;
        margin: 0 0 150px 0;
    
    }
    
    .sectionBeforeAnimatedContent div{
        width: 50%;
        margin: 0 15px;
    }
    
    .sectionBeforeAnimatedContent div p{
        margin: 0;
    }
    
    .sectionBeforeAnimatedContent div span{
        margin-top: 20px;
        display: inline-block;
        // text-decoration: underline;
        color: #066FEF !important;
        cursor: pointer;
    }
    
    .sectionBeforeAnimatedContent .noShow {
        max-height: 0px;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.3s, opacity 0.3s;
      }
      
      .sectionBeforeAnimatedContent .show {
        max-height: 2000px;
        opacity: 1;
        transition: max-height 0.3s, opacity 0.3s;
      }
      
    
    .modalForDetailedSection .buttonInModal {
        color: #066FEF !important;
        font-size: 16px !important;
        border: 1px solid #066FEF;
        border-radius: 25px;
        padding: 0 55px;
        margin-left: 40px;
        margin-bottom: 40px;
        font-weight: 400;
        background-color: #fff !important;
    }
    
    .wrapTextInModal{
        padding: 0 110px 0 30px;
        color: #00142E;
    }

    .section5-modal-content p,
    .section6-modal-content p{
        width: 50%;
        display: inline-block;
    }
    .section5-modal-content,
    .section6-modal-content{
        margin-top: 20px!important;
        margin-bottom: 20px!important;
    }
    
    
    .section5-modal-content img,
    .section6-modal-content img{
        position: absolute;
        top: 20px;
        right: 10%;
    }
    
    #modal7 .wrapTextInModal{
        min-height: 220px;
    }
    
    // #modal12 .wrapTextInModal{
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     padding: 0 30px;
    // }
    
    // #modal12 section{
    //     width: 48%;
    // }
    
    .lightBlueText{
        color: #066FEF;
        font-size: 14px;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
    .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
        color: #066FEF !important;
    }
    
    #secondSection4_1 .wrapTextInModal, #secondSection4_2 .wrapTextInModal, #secondSection6 .wrapTextInModal, #secondSection7 .wrapTextInModal, #secondSection8 .wrapTextInModal{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    .verticalLine{
        display: block;
        width: 2px;
        height: 286px;
        background: #B4B4B4;
    }
    
    .wrapButtonsForCarousel{
        display: flex;
        align-items: center;
    }
    
    #secondSlide a, .wrapTextInModal a{
        color: #066FEF;
    }
    
    .wrapTextInModal p{
     margin: 0;
    }
    
    .wrapTextInModal section{
      margin: 30px 0;
    }

    .wrapTextInModal section.big-content{
        margin: 0 0;
      }
    
    .wrapTextInModalForSectionWithNoTopMargin section{
        margin-top: 0 !important;
    }
    
    .wrapTextInModal section p{
        margin: 5px 0;
      }
    
    .blueBoldText{
        font-weight: 600;
        color: #00095B;
    }

    .longWordsShallNotPass{
        font-weight: 600;
    }
    
    // #modal16 .wrapTextInModal{
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 0 30px;
    // }
    
    // #modal16 section{
    //     width: 48%;
    // }
    
    @media only screen and (max-width: 1150px) {
        .modalForDetailedSection{
            width: auto;
            margin: 15px;
        }
    
        .wrapImagesInModal{
            padding-left: 0;
        }
    
        .wrapTextInModal{
            padding: 15px;
        }
    
        #secondSection1 .wrapTextInModal {
            padding: 0 30px 0 30px;
        }
    
        .wrapAllSide{
            flex-direction: column;
        }
    
        .specialArrowInTheModal {
            transform: rotate(90deg);
        }
    
        .doNotDisplayOnTheSmallerScreens{
            display: none;
        }
    
        .flip-card-back{
            text-align: center;
        }

        .section5-modal-content p,
        .section6-modal-content p{
            width: 100%;
            display: block;
        }

        .section5-modal-content,
        .section6-modal-content{
            margin-top: 20px!important;
            margin-bottom: 20px!important;
        }
        
        
        .section5-modal-content img,
        .section6-modal-content img{
            position: relative;
            top: 0px;
            right: 0px;
        }
    }
    
    @media only screen and (max-width: 992px) {
    
        #secondSection4_1 .wrapTextInModal, #secondSection4_2 .wrapTextInModal, #secondSection6 .wrapTextInModal, #secondSection7 .wrapTextInModal, #secondSection8 .wrapTextInModal{
            flex-direction: column;
        }
        .wrapCustomWordsInModal p {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: baseline;
            margin: 0 !important;
        }
        .wrapCustomWordsInModal span {
            width: 100%;
            margin: 15px 0;
        }
    
        #authenticity{
            font-size: 12px;
        }
        #kept_promises{
            font-size: 12px;
        }
        #trust{
            font-size: 43px;
        }
        #confidence{
            font-size: 20px;
        }
        #support{
            font-size: 20px;
        }
        #communication{
            font-size: 20px;
        }
    
        #secondSection4 .wrapTextInModal {
             padding: 0;
        }
    
        .sectionBeforeAnimatedContent{
            flex-direction: column;
            margin-bottom: 25px;
        }
        
        .sectionBeforeAnimatedContent div{
            width: 100%;
            margin: 10px 0;
        }
        
        .sectionBeforeAnimatedContent div p{
            margin: 0;
        }
        
        .sectionBeforeAnimatedContent div a{
            color: #066FEF !important;
            text-decoration: none;
        }
    
        #secondSection2 .verticalLine{
            height: 2px !important;
            transform: rotate(0deg);
            width: 180px !important;
            margin: 0px auto 30px auto;
        }
        
        #secondSection2 .wrapTextInModal section:first-child{
            width: 100%;
        }
        
        #secondSection2 .wrapTextInModal section:last-child{
            width: 100%;
        }
        
        #secondSection2 .wrapTextInModal{
            flex-direction: column;
        }
    }
    
    @media only screen and (max-width: 768px) {

        #share{
            width: 256px !important;
            // height: 320px !important;
        }
    
        #feedbackField2{
            width: 180px;
        }

        #share .buttonInModal {
            margin: 15px 0 0 0;
        }

        .customFlexInModal,
        .customFlexInModalWithImage{
            flex-direction: column-reverse;
            align-items: center;
        }
    
        // #modal12 .wrapTextInModal{
        //     flex-direction: column;
        //     padding: 0;
        // }
    
        .verticalLine {
            width: 200px;
            height: 2px;
            transform: rotate(0deg);
            margin-bottom: 30px;
        }
    
        // #modal12 section{
        //     width: 100%;
        // }
    
        // #modal16 .wrapTextInModal{
        //     flex-direction: column;
        //     align-items: center;
        //     padding: 0;
        // }
    
        // #modal16 section{
        //     width: 100%;
        // }
    
        .wrapTextInModal{
            min-height: max-content;
        }
        .wrapBottomNavForCarousel{
            flex-direction: column-reverse;
        }
    
        .wrapNavigarionForCarousel {
            display: flex;
            margin: 0;
        }
    
        .modalForDetailedSection .buttonInModal {
            padding: 0 5px;
        }
    
        .wrapImagesInModal{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .wrapImagesInModal img:first-child{
            width: 66%;
        }
        .wrapImagesInModal img:last-child{
            width: 35%;
        }
    
        .modalForDetailedSection .buttonInModal {
            margin: auto;
        }
    
        .modalForDetailedSection h2{
            margin-left: 0px;
        }

        
    }
    
    @media only screen and (max-width: 450px) {
        .flip-card {
            width: 180px;
            height: 150px;
        }
    }
    // ----------------------------------------- end of interactive map -> detail-page -> modal -----------------------------------------
    
    
    // ----------------------------------------- material components overrides -----------------------------------------
    .home-search .mat-mdc-text-field-wrapper,
    .sg-search .mat-mdc-text-field-wrapper{
        border-radius: 30px!important;
        border: 1px solid #B4B4B4;
    }
    
    .mat-mdc-text-field-wrapper,
    .home-search .mat-mdc-button{
      height: 40px!important;    
    }
    
    .home-search .mat-mdc-input-element,
    .sg-search .mat-mdc-input-element{
        padding-left: 15px;
    }
    
    .home-search .mat-mdc-button{
      top: -8px;
    }
    
    .mat-mdc-form-field-infix{
      padding-top: 9px!important;
      padding-bottom: 9px!important;
    }

    .bookmarks-left-column .mat-mdc-form-field-infix{
        padding-top: 5px!important;
        padding-bottom: 9px!important;
      }
    
    .home-search  .mat-mdc-form-field-flex
    .sg-search  .mat-mdc-form-field-flex{
        background-color: #FFFFFF;
    }
    
    .home-search input
    .sg-search input{
        padding-left: 20px;
    }
    
    .home-search .mdc-line-ripple::before,
    .home-search .mdc-line-ripple::after,
    .sg-search .mdc-line-ripple::before,
    .sg-search .mdc-line-ripple::after{
        border-bottom: none;
    }
    
    .home-search .mat-mdc-button,
    .sg-search .mat-mdc-button{
        background-color: #066FEF;
        color: #FFFFFF!important;
        height: 56px;
        border-radius: 0;
    }
    
    .home-search mat-icon,
    .sg-search mat-icon{
        width: 23px!important;
        height: 23px!important;
        font-size: 27px!important;
    }
    
    .sg-search .mat-mdc-text-field-wrapper,
    .sg-search .mat-mdc-button{
        height: 40px!important;    
      }
    
      .sg-search .mat-mdc-form-field-infix{
        padding-top: 9px!important;
        padding-bottom: 9px!important;
      }
    
      .mdc-text-field--filled:not(.mdc-text-field--disabled),
      .mdc-text-field--filled:not(.mdc-text-field--disabled){
        background-color: #FFFFFF!important;
      }

      .upperSection .mdc-text-field--filled:not(.upperSection .mdc-text-field--disabled),
      .upperSection .mdc-text-field--filled:not(.upperSection .mdc-text-field--disabled){
        background-color: transparent!important;
      }

      .upperSection .mat-mdc-select-value-text,
      .upperSection .mat-mdc-select-arrow svg{
        color: #FFFFFF;
      }

      .upperSection .mat-mdc-select-value-text{
        font-family: 'Ford F1 Regular', "Ford Antenna Regular", "Helvetica Neue", sans-serif !important;
        font-size: 14px;
        font-weight: bold;
      }

      .upperSection  .mat-mdc-text-field-wrapper{
        height: 60px!important;
      }

      .upperSection .mat-mdc-form-field:hover,
      .upperSection .mat-mdc-form-field-focus-overlay {
        background-color: #00142E!important;
      }
    
      .sg-search .mat-mdc-button{
        top: -8px;
      }
    
      .mobile-dropdown .mat-mdc-text-field-wrapper,
      .sort-select .mat-mdc-text-field-wrapper {
        height: 50px!important;
      }
    
      .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after{
        content: url("assets/logos/icon-arrow-down.png")!important;
        border: none!important;
        right: 5%!important;
        top: 30%!important;
      }

      .mat-mdc-select-value{
        padding-top: 12px;
    }

    .search-button:disabled{
        background-color: #808080;
    }

    .bookmarks-left-column .mat-mdc-text-field-wrapper{
        border-radius: 15px;
    }

    .bookmarks-left-column .mat-mdc-form-field-focus-overlay{
        background-color: #FFFFFF;
    }

    .bookmarks-left-column .mdc-line-ripple::before{
        border-bottom: none!important;
    }

    .bookmarks-left-column .mat-mdc-form-field-infix label{
        margin-top: -10px;
    }

    .bookmarks-left-column .mat-mdc-select-value{
        padding-top: 0;
    }

    .bookmarks-left-column .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
        transform: translateY(-3px);
    }

    mat-select .mat-mdc-select-arrow-wrapper{
        margin-top: 15px;
    }  

    .bookmarks-left-column mat-select .mat-mdc-select-arrow-wrapper{
        margin-top: 5px;
    }  

    .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay{
        opacity: 0;
    }

    .bookmarks-left-column .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after{
        border: none;
    }

    .mat-typography h3{
        margin: 0 0 5px!important;
    }

    .mat-mdc-form-field.sg-search:hover{
        background-color: transparent;
    }
    
    .mat-mdc-form-field-focus-overlay{
        background-color: #FFFFFF;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after{
        border: none;
    }

    .mdc-floating-label.mdc-floating-label--float-above{
        display: none;
    }

    .search-parameter mat-select{
        width: 120px;
    }

    .search-parameter .mat-mdc-select-value{
        padding-top: 0;
    }

    .search-parameter .mat-mdc-select-value-text{
        font-family: 'Ford F1 Regular', 'Ford Antenna Regular', "Helvetica Neue", sans-serif;
        color: #00142E;
        font-size: 12px;
      }
    
      .search-parameter .mat-mdc-select-arrow{
        color: #00142E;
      }
    
      .cdk-overlay-container{
        z-index: 100000;
      }

      .lang-wrapper .mat-mdc-text-field-wrapper, 
      .lang-wrapper .mat-mdc-dialog-actions {
        padding-left: 0;
      }

      .lang-wrapper .mat-mdc-select{
        font-size: 14px!important;
      }

      .lang-wrapper .mat-mdc-select-min-line {
        font-weight: bold!important;
        color: #066FEF!important;
      }

      .lang-wrapper .mat-mdc-select-arrow{
        color: #066FEF!important;
      }

    // mat-option{
    //     padding: 10px!important;
    // }

    @media only screen and (max-width: 768px) {    
        .bookmarks-left-column .mat-mdc-form-field{
            width: 100%;
        }
    }
    // ----------------------------------------- end of material components overrides -----------------------------------------
    
    
    
    .cdk-global-overlay-wrapper > .main-modal-btn-line ~ .cdk-overlay-dark-backdrop {
        background: #00095B !important;
      }
    
      app-onboarding-modal mat-dialog-content{
        padding: 0 !important;
        max-width: 800px;
    }
    
    app-onboarding-modal .mat-mdc-dialog-container::-webkit-scrollbar {
        width: 0.2em;
    }
     
    app-onboarding-modal .mat-mdc-dialog-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
     
    app-onboarding-modal .mat-mdc-dialog-container::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    //   outline: 1px solid slategrey;
    }
    
    //firefox
    
    app-onboarding-modal .mat-mdc-dialog-container {
        scrollbar-width: none;
      }
    
    
      // ----------------------------------------- cards design -----------------------------------------
    
      .categories-container {
        display: flex;
        justify-content: center;
      }
      
      .categories-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        text-align: center;
        margin: 0 -15px;
        width: 100%;
      }
      
      .category-row {
        width: 100%; 
        flex-wrap: inherit;
        display: flex;
        justify-content: space-between;
      }
      
      .category-card {
        flex: 0 0 calc(50% - 30px);
        margin: 15px;
        box-sizing: border-box;
        height: 178px;
      }

      .category-card .mat-mdc-card-content{
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom: 0!important;
      }

      .search-card .mat-mdc-card-content{
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom: 0!important;
      }
      
    .categories-block .linkInTheCategoryCard {
        height: 100% !important;
    }

    .linkInTheCategoryCard{
        padding: 0 25px 0 25px!important;
    }

    .linkInTheCategoryCard .mat-mdc-card-header {
        padding: 35px 16px 0;
    }

    .linkInTheCategoryCard .mat-mdc-card-content:last-child{
        padding-bottom: 35px;
    }
    
    .category-card .wrapIcon{
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: top;
        padding-top: 30px;
    }
    
    .category-card .wrapIcon img{
        width: 100%;
    }
    
    
    .category-card .wrapContent {
        width: calc(100% - 100px);
        display: inline-block;
    }
    
    .category-card .mdc-button__label{
        width: 100%;
        height: 100%;
        text-align: left;
    }

    mat-card-title,
    .wrapDataUnderHeaderInDetailedSection p:first-child,
    .wrapDataUnderHeaderInDetailedSection p:nth-child(3){
        display: -webkit-box!important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding-bottom: 0 !important;
    }

    .wrapDataUnderHeaderInDetailedSection p:first-child{
        max-width: 250px;
    }

    .wrapDataUnderHeaderInDetailedSection p:nth-child(3){
        max-width: 150px;
    }
    
    @media only screen and (max-width: 768px) {    

        .home-page-categories .category-card{
            height: 65px;
            min-height: 65px;
        }

        .home-page-categories .mat-mdc-card-title-group,
        .category-card .mat-mdc-card-title-group{
            display: block;
        }

        .home-page-categories .category-card .wrapContent {
            width: calc(100% - 50px);
        }

        .home-page-categories mat-card-title,
        .category-card mat-card-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 95%;
            display: inline-block!important;
        }
      
        .category-row{
            display: block;
        }

        .category-card {
          flex: 0 0 calc(100% - 30px);
          min-height: 160px;
          height: 160px;
          margin: 10px;
        }
      
        .category-card mat-card-content {
          display: none;
        }
      
        .category-card .mdc-button{
          justify-content: left;
        }
      
        .category-card .mat-mdc-card-header {   
          padding: 16px 5px 5px 5px;
        }

        .category-card .wrapIcon{
            padding-top: 15px;
        }

        .linkInTheCategoryCard {
            padding: 0 10px 0 10px !important;
        }

        .mat-mdc-card-content{
            padding: 0 5px!important;
        }

        .wrapDataUnderHeaderInDetailedSection{
            margin: 0 0 0 45px!important;
            height: 17px;
          }

          .wrapDataUnderHeaderInDetailedSection p{           
            font-size: 7px!important;
          }
      }
    
    // ----------------------------------------- end of cards design -----------------------------------------
    
    .wrapStarsInRatingPopup svg{
        cursor: pointer;
        padding: 0 4px;
        margin: 25px 0 30px 0;
    }

    .wrapStarsInRatingPopup svg:hover path#Path_44 {
        fill: #066FEF !important;
    }

    .wrapStarsInRatingPopup svg:first-child
    {
        padding: 0 4px 0 0;
    }

    .wrapStarsInRatingPopup svg:last-child{
        padding: 0 0 0 4px;
    }


    #feedbackField .mat-form-field-underline {
        display: none !important;
    }

    .rewiewSection #feedbackField{
        resize: none;
        width: calc(100% - 30px);
        padding: 15px;
        margin: auto;
        height: 120px;
        border: none;
        background: #ECEBFA;
        border-radius: 10px;
        margin-top: 10px;
        color: #00142E;
        font-size: 14px;
    }

    .wrapStarsInRatingPopup h2, .rewiewSection h2{
        margin: 0 !important;
        font-size: 18px !important;
        color: #00095B !important;
        font-weight: bold !important;
    }

    .rewiewSection #feedbackField::placeholder{
        color: #00142E;
        padding: 0;
        font-size: 14px;
    }

    #rate{
        max-width: 540px !important;
        margin: 30px 40px;
    }

    #rate mat-dialog-content{
        padding: 0px;
    }

    .wrapStarsInRatingPopup p {
        padding-top: 10px;
        color: #00142E;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
    }

    #buttonsInRating{
        justify-content: space-between !important;
        padding: 0 !important;
        align-items: baseline !important;
    }

    #buttonsInRating .buttonInModal{
        padding: 12px 40px !important;
        margin: 20px 0 0 0 !important;
        cursor: pointer;
      }

    #buttonsInRating .cancelButtonInRating{
        color: #066FEF;
        text-decoration: underline;
        cursor: pointer;
    }

    @media only screen and (max-width: 1150px) {    
        #rate{
            width: 540px !important;
        }
    }

    @media only screen and (max-width: 768px) {    
        #rate{
            max-width: 220px !important;
            width: 100%;
            margin: 20px;
        }

        .rewiewSection #feedbackField{
            resize: none;
            width: 175px;
            max-height: 120px;
            min-height: 60px;
        }
    }

    /* Style for the disabled button */
#buttonsInRating .buttonInModal:disabled {
    background-color: #ccc !important;
    color: #666 !important;
    border: 2px solid #ccc !important;
    cursor: not-allowed !important; 
  }

// ----------------------------------------- sort design -----------------------------------------

.sort-select .mdc-text-field--filled:not(.mdc-text-field--disabled),
.sort-select .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

// ----------------------------------------- end of sort design -----------------------------------------


// ----------------------------------------- paginator design -----------------------------------------

.custom-paginator {
    margin-top: 50px;
    text-align: left;
}

.custom-page {
    display: inline-block;
    color: #00142E;
    font-size: 14px;
    margin-right: 3px;
    padding: 5px 8px;
    text-decoration: none !important;
    position: relative !important;
}

.custom-page:after {    
background: none repeat scroll 0 0 transparent;
bottom: 5px;
content: "";
display: block;
height: 1px;
left: 50%;
position: absolute;
background: #066FEF;
transition: width 0.3s ease 0s, left 0.3s ease 0s;
width: 0;
}
.custom-page:hover:after { 
width: 100%; 
left: 0%; 
} 

.custom-page:hover {
    cursor: pointer;
}

.prev-page,
.next-page {
    display: inline-block;
    vertical-align: text-top;
}

.prev-page:hover,
.next-page:hover {
    cursor: pointer;
}

.prev-page {
    margin-right: 10px;
}

.next-page {
    margin-left: 10px;
}

.custom-page.active {
    font-weight: 600;
    color: #066FEF;
}

@media only screen and (max-width: 768px) {    
    .custom-paginator {
        display: flex;
        align-items: center;
        justify-content: space-between; 
    }
}
// ----------------------------------------- end of paginator design -----------------------------------------

// ----------------------------------------- tooltip design -----------------------------------------

.custom-tooltip{
    display: inline-block;  
    position: relative;  
}

.tooltip-content{
    display: none;    
    position: absolute;
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 5px;
    top: 5px;
    left: 60px;
    z-index: 10;
    min-width: 200px; 
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    text-align: left!important;
    font-weight: 400!important;
    line-height: 20px!important;
}

.tooltip-content p{
    font-size: 12px;
    text-align: left!important;
    font-weight: 400!important;
    line-height: 20px!important;
}

.tooltip-arrow{
    position: absolute;
    left: -16px;
    top: -17px;   
}

.got-it-btn{
    width: fit-content!important;
    height: 24px!important;
    line-height: 0!important;
    font-size: 12px!important;
}

@media only screen and (max-width: 768px) {    
    .tooltip-arrow{
       display: none;
    }

    .tooltip-content {        
        top: 30px;
        left: -50px;       
    }

    #aboutTooltipMobileUS{
        left: -50%;
        transform: translate(-30%, 0px);
    }

    #aboutTooltipMobileCAEN{
        left: -50%;
        transform: translate(-30%, 0px);
    }

    #aboutTooltipMobileCAFR{
        left: -300%;
        transform: translate(-30%, 0px);
    }

    #aboutTooltipMobileGB{
        left: -260px;
    }
}

// ----------------------------------------- end of tooltip design -----------------------------------------

#verificationBtn{
    position: relative !important;
    left: 50% !important;
    transform: translate(-50%, 50%) !important;
}

@media only screen and (max-width: 768px) {    
    .custom-page {
        padding: 5px 1px;
    }
}

@media only screen and (max-width: 360px) {
    .longWordsShallNotPass{
        max-width: 50px !important;
      }
}

.wrapTextInModal a{
    word-break: break-all;
}

.cdk-overlay-pane, .mdc-dialog__container{
    max-height: 90vh !important;
}

.mat-mdc-dialog-content {
    max-height: 45vh !important;
}

.errorHeader{
    color: #00095B !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

.errorMessageHolder{
    color: #00142E !important;
    font-size: 12px !important;
}

// ----------------------------------------- loader -----------------------------------------
.loaderHolder{
    position: absolute;
    width: calc(100% - 30px);
    height: 100%;
    background: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #066FEF !important;
}

// ----------------------------------------- end of loader -----------------------------------------

// ----------------------------------------- screen reader -----------------------------------------
.justForScreenReader{
    font-size: 1px;
    position: absolute;
    color: transparent;
}
// ----------------------------------------- end of screen reader -----------------------------------------


.cdk-overlay-dark-backdrop {
    backdrop-filter: blur(15px);
    background: #00142ec7 !important;
}

.niceLineForFooterNav {
    display: inline-block;
    text-decoration: none !important;
    position: relative !important;
}

.niceLineForFooterNav:after {    
background: none repeat scroll 0 0 transparent;
bottom: 0px;
content: "";
display: block;
height: 1px;
left: 50%;
position: absolute;
background: #00142E;
transition: width 0.3s ease 0s, left 0.3s ease 0s;
width: 0;
}
.niceLineForFooterNav:hover:after { 
width: 100%; 
left: 0%; 
} 

.hideIfNeeded{
    display: none;
}

.DontHideIfNeeded{
    display: none;
}

.HideIfNeededGB{
    display: none !important;
}

#HideIfNeededGB{
    display: none !important;
}

.DontHideIfNeededGB{
    display: block !important;
}

.customMarginForModalText{
    margin-right: 5px;
}

.makeCertainSize .mdc-button__label{
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.DoNotShowIfNotNeeded{
    display: none;
}

.dontDoBold{
    font-weight: normal !important;
}

.DontShowForGB{
    display: none !important;
}

.ShowForGB{
    display: block !important;
}

.SpecialInctructionsForGB{
    width: 790px !important;
    left: 0 !important;
}

@media only screen and (max-width: 1200px) {    
    .SpecialInctructionsForGB{
        width: 580px !important;
    }
}

@media only screen and (max-width: 991px) {    
    .SpecialInctructionsForGB{
        left: calc(50% - 290px) !important;
    }
}

@media only screen and (max-width: 620px) {    
    .SpecialInctructionsForGB{
        width: 300px !important;
        left: calc(50% - 150px) !important;
    }
}

.SpecialPosition1{
    top: 170px !important;
    left: 200px !important;
}

.SpecialPosition2{
    left: 490px !important;
    top: 45px !important;
}

.SpecialPosition3{
    right: 250px !important;
    top: 360px !important;
}

@media only screen and (max-width: 1050px) {    
    .SpecialPosition1{
        top: 90px !important;
        left: 130px !important;
    }
    
    .SpecialPosition2{
        left: 323px !important;
        top: 20px !important;
    }
    
    .SpecialPosition3{
        right: 160px !important;
        top: 225px !important;
    }
}

@media only screen and (max-width: 768px) {   

    .SpecialPosition1{
        left: 50% !important;
        transform: translateX(-265%) !important;
    }
    
    .SpecialPosition2{
        left: 50% !important;
        transform: translateX(120%) !important;
    }
    
    .SpecialPosition3{
        right: 50% !important;
        transform: translateX(100%) !important;
    }
}

@media only screen and (max-width: 568px) {    
    .SpecialPosition1{
        left: 50% !important;
        top: 28px !important;
        transform: translateX(-200%) !important;
    }
    
    .SpecialPosition2{
        left: 50% !important;
        top: -7px !important;
        transform: translateX(70%) !important;
    }
    
    .SpecialPosition3{
        right: 50% !important;
        top: 105px !important;
        transform: translateX(50%) !important;
    }
}

.customWidth .mdc-button__label{
    display: inline-block;
    width: 175px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {   
    .customWidth .mdc-button__label{
        width: fit-content;
        max-width: 110px;
    }
}

.longWordsShallNotPassFR{
    max-width: 119px !important;
}

@media only screen and (max-width: 520px) {  
    .longWordsInTheDetailSectionFooterHolder{
        display: flex;
        align-items: center;
    } 
    .longWordsInTheDetailSectionFooter{
        display: inline-block;
        max-width: 65px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .longWordsShallNotPassFR{
        max-width: 100px !important;
    }
}

@media only screen and (max-width: 425px) {   
    .longWordsInTheDetailSectionFooter{
        max-width: 40px;
    }
}

@media only screen and (max-width: 375px) {   
    .longWordsInTheDetailSectionFooter{
        max-width: 14px;
    }

    .longWordsShallNotPassFR{
        max-width: 50px !important;
    }
}

@media only screen and (max-width: 420px) {   
    .ShorterLength{
        max-width: 100px !important;
    }

    .shortenButton .mdc-button__label{
        max-width: 200px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (max-width: 375px) {   
    .ShorterLength{
        max-width: 65px !important;
    }
}


.solveSpacing{
    padding: 0 5px;
}

.solveSpacingViewSection{
    padding: 0 2px;
}


// ----------------------------------------- language based classes -----------------------------------------

.hideForCA{
    display: none !important;
}

// ----------------------------------------- end of language based classes -----------------------------------------


.smallTextForGB{
    font-size: 12px !important;
}


.specialHeaderForGB{
    font-size: 20px !important;
    padding: 10px 24px !important;
}

.specialParagraphForGB{
    padding: 10px 24px !important;
}

.specialSmallParagraphForGB{
    padding: 10px 24px !important;
    font-size: 12px !important;
}

.RemoveFivePXMarginForGBOnly{
    margin: 0 !important;
}

.makeMarginLeftFivePX{
    margin-left: 5px;
}

.DontMakeItBoldForGB{
    font-weight: 300 !important;
}

.extraPadding {
    padding-top: 60px;
  }
  
.extraPaddingContent {
    padding-top: 110px; 
}

@media only screen and (max-width: 375px) {   
    .extraPaddingContent {
        padding-top: 130px; 
    }
}

.MarginJustForGB{
    margin-right: -4px;
}